<template>
  <div>
    <EstablishmentForDisableFirstPartComp v-bind:mobile="mobile"/>
    <CommonEstablishmentsComp v-bind:mobile="mobile"/>
    <EstablishmentForDisableSecondPartComp v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import EstablishmentForDisableFirstPartComp from '@/components/desktop/Establishments/EstablishmentForDisableFirstPartComp'
import CommonEstablishmentsComp from '@/components/desktop/Establishments/CommonEstablishmentsComp.vue'
import EstablishmentForDisableSecondPartComp from '@/components/desktop/Establishments/EstablishmentForDisableSecondPartComp.vue'

  export default {
    name: 'EstablishmentForDisable',
    props: ['mobile'],
    components: {
      EstablishmentForDisableFirstPartComp,
      CommonEstablishmentsComp,
      EstablishmentForDisableSecondPartComp
    },
  }
</script>

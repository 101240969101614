<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle mainTitle2Lines'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" class="" no-gutters v-if="mobile">
      <v-col cols="6">
        <p class="text1 textMobile" v-html="$t('text1')"></p>
      </v-col>
      <v-col cols="4">
        <v-img
            :src="require('@/assets/picto/pictoPeople1.svg')"
            contain
            height="200"
        />
      </v-col>
      <v-col cols="10">
        <p class="text2 textMobile" v-html="$t('text2')"></p>
        <p class="text1 textMobile" v-html="$t('text3')"></p>
        <p class="text2 textMobile" v-html="$t('text4')"></p>
        <br v-if="$i18n.locale !== 'fr'">
        <br v-if="$i18n.locale !== 'fr'">
        <h3 class="subTitleMobile"> {{ $t('subTitle') }}</h3>
      </v-col>
    </v-row>
    <v-row justify="center" class="sectionLeft" no-gutters v-if="!mobile">
      <v-col cols="8">
        <p class="text1 textLeft" v-html="$t('text1')"></p>
        <p class="text2 textLeft" v-html="$t('text2')"></p>
        <p class="text1 textLeft" v-html="$t('text3')"></p>
        <p class="text2 textLeft" v-html="$t('text4')"></p>
        <br v-if="$i18n.locale !== 'fr'">
        <br v-if="$i18n.locale !== 'fr'">
        <h3 class="subTitle"> {{ $t('subTitle') }}</h3>
      </v-col>
      <v-col cols="4">
        <v-img
            class="imgPeople"
            :src="require('@/assets/picto/pictoPeople1.svg')"
            contain
            height="500"
        />
      </v-col>
      <v-col cols="12" align-self="center">
        <v-icon
            class="iconEndSection"
            size="100"
            color="#1579A7"
        >
          mdi-chevron-down
        </v-icon>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'EstablishmentForDisable',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Establishment welcoming people with disabilities",
    "text1": "Since its creation, Vitaé Sport Santé has attached particular importance to the development of physical activity for people with a mental handicap or mental disorder.",
    "text2": "Indeed, mental disability and mental illnesses are poorly understood and poorly understood pathologies that are often the subject of strong stigmatization. People with these disorders often find themselves in a situation of breakdown in social ties and suffer from isolation. They are at high risk of exclusion and find it difficult to stabilize their mental, physical and emotional state.",
    "text3": "Adapted physical and sporting activity programs are necessary for better health, a tool in the fight against sedentary lifestyle and social isolation;",
    "text4": "they must therefore take their place within institutions, as part of the establishment plan, accompanied by an extension within sports associations to boost this practice, motivate practitioners and promote integration approaches.",
    "subTitle": "The benefits of physical activity for people with mental disabilities or mental health problems:"
  },
  "fr": {
    "mainTitle": "Etablissement accueillant des publics handicapés",
    "text1": "Depuis sa création, Vitaé Sport Santé accorde une importance toute particulière au développement de l’activité physique pour les publics ayant un handicap mental ou un trouble psychique.",
    "text2": "En effet, l’handicap mental et les maladies psychiques sont des pathologies méconnues et mal comprises qui font souvent l’objet d’une forte stigmatisation. Les personnes présentant ces troubles se trouvent fréquemment en situation de rupture du lien social et souffrent d’isolement. Elles se trouvent exposées à un fort risque d’exclusion et rencontrent des difficultés à stabiliser leur état mental, physique et émotionnel.",
    "text3": "Des programmes d’activités physiques et sportives adaptées sont nécessaires à une meilleure santé, outil de lutte contre la sédentarité et l’isolement social ;",
    "text4": "ils doivent donc prendre leur place au sein des institutions, dans le cadre du projet d’établissement, accompagnés de prolongement au sein d’associations sportives permettant de dynamiser cette pratique, de motiver les pratiquants et de favoriser des démarches d’intégration.",
    "subTitle": "Les bienfaits de l’activité physique pour des personnes atteintes d’un handicap mental ou d’un trouble psychique :"
  }
}
</i18n>

<style scoped>
@media screen and (min-width: 1500px) {
  .textLeft {
    text-align: left;
    padding-left: 200px;
    padding-right: 400px;
  }
  .subTitle {
    text-align: left;
    padding: 80px 300px 0 200px;
  }
}
@media screen and (max-width: 1500px) {
  .textLeft {
    font-size: 15px;
    text-align: left;
    padding-left: 150px;
    padding-right: 150px;
  }
  .subTitle {
    text-align: left;
    padding: 50px 50px 0 150px;
  }
}

.subTitleMobile {
  font-family: Comfortaa, sans-serif;
  margin-top: 50px;
  margin-bottom: 60px;
  color: #1579A7;
  text-align: left;
}

.imgPeople {
  margin-top: -50px;
  margin-right: 250px;
}

.text1 {
  color: #57939E;
}

.text2 {
  color: #707070;
}

.sectionLeft {
  padding-top: 20px;
  text-align: center;
}

</style>

<template>
  <div>
    <v-expansion-panels v-if="mobile" flat class="sectionMobile">
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="">
          <h3 class="titleSectionMobile"> {{ $t(firstSection.title)}}</h3> <br>
          <template v-slot:actions>
            <v-icon color="#57939E" x-large>
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="textSectionMobile" v-html="$t(firstSection.text, { route: firstSection.link })"></p>
          <br>
          <v-img
              class="imgSections"
              :src="firstSection.image"
              contain
              height="150"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="">
          <h3 class="titleSectionMobile"> {{ $t(secondSection.title)}}</h3> <br>
          <template v-slot:actions>
            <v-icon color="#57939E" x-large>
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="textSectionMobile" v-html="$t(secondSection.text, { route: secondSection.link })"></p>
          <br>
          <v-img
              class="imgSections"
              :src="secondSection.image"
              contain
              height="150"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="">
          <h3 class="titleSectionMobile"> {{ $t(lastSection.title)}}</h3> <br>
          <template v-slot:actions>
            <v-icon color="#57939E" x-large>
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="textSectionMobile" v-html="$t(lastSection.text, { route: lastSection.link })"></p>
          <br>
          <v-img
              class="imgSections"
              :src="lastSection.image"
              contain
              height="150"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row justify="center" class="sectionPart" no-gutters v-if="!mobile">
      <v-col cols="6">
        <v-img
            class="imgSections"
            :src="firstSection.image"
            contain
            height="175"
        />
        <br>
        <h3 class="titleSection"> {{ $t(firstSection.title)}}</h3> <br>
        <p class="textSection" v-html="$t(firstSection.text, { route: firstSection.link })"></p>
      </v-col>
      <v-col cols="6">
        <v-img
            class="imgSections"
            :src="secondSection.image"
            contain
            height="175"
        />
        <br>
        <h3 class="titleSection"> {{ $t(secondSection.title)}}</h3> <br> <br>
        <p class="textSection" v-html="$t(secondSection.text, { route: secondSection.link })"></p>
      </v-col>
      <v-col cols="12" class="sectionsSecondPart">
        <v-img
            class="imgSections"
            :src="lastSection.image"
            contain
            height="175"
        />
        <br>
        <h3 class="titleLastSection"> {{ $t(lastSection.title)}}</h3> <br>
        <p class="textLastSection" v-html="$t(lastSection.text, { route: lastSection.link })"></p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'EstablishmentForDisable',
    props: ['mobile'],
    data: () => ({
      firstSection: {
        image: require('@/assets/vitaeImg/section21.png'),
        title: 'firstSection',
        link: '/activities/1',
        text: 'firstSectionText',
      },
      secondSection: {
        image: require('@/assets/vitaeImg/section22.png'),
        title: 'secondSection',
        link: '/activities/3',
        text: 'secondSectionText',
      },
      lastSection: {
        image: require('@/assets/vitaeImg/section20.png'),
        title: 'lastSection',
        link: '/activities/2',
        text: 'lastSectionText',
      }
    }),
  }
</script>

<i18n>
{
  "en": {
    "firstSection": "Adapted physical activities",
    "firstSectionText": "We offer many adapted physical activities in institutes that adapt to all fitness levels and all tastes: <strong> gentle gym, yoga, Pilate, aquagym, velaqua, fall prevention, active walking, Nordic walking. </strong> <a class='linkText' href='{route}'>SEE MORE</a>",
    "secondSection": "Olympics",
    "secondSectionText": "The Olympics brings together several <strong> fun activities </strong> of skill, throwing and precision. <a class='linkText' href='{route}'>SEE MORE</a>",
    "lastSection": "My physical activity adapted from a distance",
    "lastSectionText": "Using a high-performance TV webcam, we offer adapted physical activity sessions and <strong> pre-recorded exercises to stimulate residents on a daily basis! </strong> <a class='linkText' href='{route}'>SEE MORE</a>"
  },
  "fr": {
    "firstSection": "Les activités physiques adaptées",
    "firstSectionText": "Nous proposons de nombreuses activités physiques adaptées en institut qui s’adaptent à tous les niveaux de forme et à toutes les envies : <strong> gym douce, yoga, Pilate, aquagym, velaqua, prévention de la chute, marche active, marche nordique. </strong> <a class='linkText' href='{route}'>VOIR PLUS</a>",
    "secondSection": "Les olympiades",
    "secondSectionText": "Les olympiades regroupent plusieurs <strong> activités ludiques </strong> d’adresse, de lancer et de précision. <a class='linkText' href='{route}'>VOIR PLUS</a>",
    "lastSection": "Mon activité physique adaptée à distance",
    "lastSectionText": "A l’aide d’une webcam TV haute performance, nous proposons des séances d’activité physique adaptée et <strong> des exercices pré-enregistrés pour stimuler quotidiennement les résidents ! </strong> <a class='linkText' href='{route}'>VOIR PLUS</a>"
  }
}
</i18n>

<style scoped>

@media screen and (min-width: 1500px) {
  .titleSection {
    text-align: center;
    font-family: Comfortaa, sans-serif;
    color: #57939E;
    padding-right: 300px;
    padding-left: 300px;
  }
  .titleLastSection {
    text-align: center;
    font-family: Comfortaa, sans-serif;
    color: #57939E;
    padding-right: 700px;
    padding-left: 700px;
  }
  .textLastSection {
    text-align: left;
    color: #808080;
    padding-right: 500px;
    padding-left: 500px;
  }
}
@media screen and (max-width: 1500px) {
  .titleSection {
    text-align: center;
    font-family: Comfortaa, sans-serif;
    color: #57939E;
  }
  .titleLastSection {
    text-align: center;
    font-family: Comfortaa, sans-serif;
    color: #57939E;
  }
  .textLastSection {
    text-align: left;
    color: #808080;
    padding-right: 350px;
    padding-left: 350px;
  }
}

.sectionMobile {
  padding-top: 50px;
  padding-bottom: 50px;
}
.textSectionMobile {
  font-size: 14px;
  text-align: left;
  color: #808080;
}
.titleSectionMobile {
  margin-right: 100px;
  font-family: Comfortaa, sans-serif;
  color: #57939E;
}
.sectionPart {
  padding-top: 100px;
  padding-bottom: 75px;
  padding-left: 100px;
  padding-right: 100px;
}

.textSection {
  text-align: left;
  color: #808080;
  padding-right: 100px;
  padding-left: 100px;
}

.textSectionMobile >>> .linkText {
  color: #57939E;
  text-decoration: underline;
}

.textLastSection >>> .linkText {
  color: #57939E;
  text-decoration: underline;
}

.textSection >>> .linkText {
  color: #57939E;
  text-decoration: underline;
}

</style>
